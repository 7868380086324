import React from 'react'
import { graphql, Link } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/Layout'
import RightCol from '../components/RightCol'
import { Container, Row, Col } from 'react-bootstrap'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import slugify from 'slugify'
import JwPagination from '../components/pagination'
import marked from 'marked'
export default class BlogList extends React.Component {
  constructor(props) {
    super(props)

    // an example array of items to be paged

    const filterEvent = this.props.data.allSanityPost.edges
    // bind the onChangePage method to this React component
    this.onChangePage = this.onChangePage.bind(this)

    // store example items and current page of items in local state
    this.state = {
      filterEvent,
      pageOfItems: [],
    }
  }

  onChangePage(pageOfItems) {
    // update local state with new page of items
    this.setState({ pageOfItems })
  }

  render() {
    const { data } = this.props
    const siteTitle = 'All Posts'
    const siteDescription = data.site.siteMetadata.description
    // const { group } = data.allSanityPost
    const features = data.features.edges

    console.log(this.state.pageOfItems)

    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        features={features}
      >
        <SEO
          title="All Posts"
          keywords={[`blog`, `steel`, `welding`, `construction`]}
          description={siteDescription}
        />
        <section className="article-list py-5">
          <Container>
            <Row>
              <Col md={`${8} pr-md-5`}>
                {this.state.pageOfItems.slice(4).map(({ node }) => {
                  const title = node.title

                  return (
                    <Row key={node.id} className="mb-5">
                      <LazyLoadComponent>
                        <Col sm={`${4} mb-3 mb-sm-0`}>
                          <div
                            className="article-list-banner mb-2"
                            style={{
                              backgroundImage: `url(${node.mainImage &&
                                node.mainImage.asset &&
                                node.mainImage.asset &&
                                node.mainImage.asset.fluid &&
                                node.mainImage.asset.fluid.src})`,
                              backgroundPosition: `center center`,
                              backgroundSize: `cover`,
                              height: `180px`,
                            }}
                          />
                        </Col>
                        <Col sm={8}>
                          <div className="category-list-content">
                            <h5>
                              <Link
                                className="article-list-link"
                                style={{ boxShadow: `none` }}
                                to={`/${node.slug.current}`}
                                title={title}
                              >
                                {title}
                              </Link>
                            </h5>
                            {node.excerpt ? (
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: marked(node.excerpt),
                                }}
                              />
                            ) : null}

                            {node.authors.length !== 0 ? (
                              <div className="category-list-author">
                                <p>
                                  <span className="text-secondary">By</span>{' '}
                                  <Link
                                    className="text-black"
                                    to={`/author/${slugify(
                                      node.authors[0].person.slug.current
                                    )}`}
                                  >
                                    {node.authors[0].person.name}
                                  </Link>{' '}
                                  <div className="d-inline">
                                    <span className="text-secondary">in</span>{' '}
                                    <span className="text-blue text-capitalize">
                                      {node.categories.length !== 0
                                        ? node.categories.map(cat => (
                                            <Link
                                              to={`/category/${slugify(
                                                cat.title.toLowerCase()
                                              )}`}
                                              key={cat.id}
                                              className="cat-link"
                                            >
                                              {cat.title}{' '}
                                              <span className="d-inline">
                                                ,
                                              </span>
                                            </Link>
                                          ))
                                        : null}
                                    </span>{' '}
                                  </div>
                                  <span className="text-secondary">
                                    {node.publishedAt
                                      ? node.publishedAt
                                      : node._createdAt}
                                  </span>
                                </p>
                              </div>
                            ) : null}
                          </div>
                        </Col>
                      </LazyLoadComponent>
                    </Row>
                  )
                })}
                <div className="d-flex justify-content-center align-items-center">
                  <JwPagination
                    items={this.state.filterEvent}
                    onChangePage={this.onChangePage}
                    pageSize={14}
                  />
                </div>
              </Col>
              <Col md={`${4} pl-md-5`}>
                <RightCol features={features} />
              </Col>
            </Row>
          </Container>
        </section>
      </Layout>
    )
  }
}

export const blogListQuery = graphql`
  query blogListQuery {
    site {
      siteMetadata {
        title
        author
      }
    }

    features: allSanityPost(
      sort: { fields: publishedAt, order: DESC }
      limit: 4
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          body
          excerpt
          featuredBlog
          _createdAt(fromNow: true)
          publishedAt(fromNow: true)
          mainImage {
            asset {
              fluid(maxWidth: 800) {
                src
              }
            }
          }
          categories {
            id
            title
          }
          authors {
            _key
            person {
              name
              slug {
                current
              }
            }
          }
        }
      }
    }

    allSanityPost(
      sort: { fields: publishedAt, order: DESC }
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          body
          excerpt
          featuredBlog
          _createdAt(fromNow: true)
          publishedAt(fromNow: true)
          mainImage {
            asset {
              fluid(maxWidth: 300) {
                src
              }
            }
          }
          categories {
            id
            title
          }
          authors {
            _key
            person {
              name
              slug {
                current
              }
            }
          }
        }
      }
    }
  }
`
